import styled from 'styled-components/macro';

type ButtonProps = {
  $opacity: number;
};

export const CustomButtonWrapper = styled.div<ButtonProps>`
  position: absolute;
  right: 30px;
  top: 30px;
  width: 90px;
  height: 50px;
  background: white;
  border: solid 2px black;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  font-family: 'Gill Sans', sans-serif;
  color: red;
  opacity: ${(props) => props.$opacity};
  transition: opacity 0.3s ease-in;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
