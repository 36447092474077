import posterPenguin from './images/penguin/poster_penguin.jpg';
import posterXmas from './images/xmas/poster_xmas.jpg';
import bannerPenguin from './images/penguin/banner_penguin.mp4';
import bannerXmas from './images/xmas/banner_xmas.mp4';
import snowflakeImage1 from './images/snowflake1.png';
import calendarBaseImage from './images/textures.png';

const snowflake1 = document.createElement('img');
snowflake1.src = snowflakeImage1;

export { posterPenguin, posterXmas, bannerPenguin, bannerXmas, snowflake1, calendarBaseImage };
