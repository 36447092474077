/* eslint-disable react/no-unknown-property */
import { Suspense, useEffect, useRef, useState } from 'react';
import type { AbstractMesh, ArcRotateCamera, PBRMaterial, Scene as SceneType } from '@babylonjs/core';
import { Vector3, Color3, Color4, ActionManager, ExecuteCodeAction, GlowLayer, CubeTexture } from '@babylonjs/core';
import type { SceneEventArgs } from 'react-babylonjs';
import { Model, Scene } from 'react-babylonjs';
import '@babylonjs/loaders/glTF';
import '@babylonjs/core/Debug/debugLayer';
import '@babylonjs/inspector';
import {
  posterXmas_texture,
  posterPenguin_texture,
  bannerXmas_texture,
  bannerPenguin_texture,
  calendarBaseTexture,
} from './textures';

import { alphaAnimation, radiusAnimation } from './animations';

type CalendarMeshType = AbstractMesh & {
  opened: boolean;
};

type CalendarProps = {
  type: 'xmas' | 'penguin';
  setOpacity: (value: number) => void;
  setButtonOpacity: (value: number) => void;
};

function Calendar({ type, setOpacity, setButtonOpacity }: CalendarProps) {
  const [scene, setScene] = useState<SceneType>();
  const [loadCalendar, setLoadCalendar] = useState<boolean>(false);
  const posterMaterial = useRef<PBRMaterial>();
  const bannerMaterial = useRef<PBRMaterial>();
  const pickedDownMesh = useRef<CalendarMeshType>();
  const sceneCamera = useRef<ArcRotateCamera>();
  const [isSceneReady, setIsSceneReady] = useState<boolean>(false);
  const calendarRoot = useRef<CalendarMeshType>();

  useEffect(() => {
    if (isSceneReady) {
      setTimeout(() => {
        if (!calendarRoot.current || !scene) return;
        calendarRoot.current.setEnabled(true);
        scene.beginAnimation(sceneCamera.current, 0, 100, false, 0.5, () => {
          setButtonOpacity(1);
        });
      }, 3000);
    }
  }, [isSceneReady]);

  const setupScene = ({ scene }: SceneEventArgs) => {
    scene.useRightHandedSystem = true;
    scene.clearColor = new Color4(0.043, 0.043, 0.1, 1);
    setScene(scene);
    scene.onReadyObservable.add(() => {
      const envMap = new CubeTexture('./assets/textures/snowy.env', scene);
      scene.environmentTexture = envMap;

      const gl = new GlowLayer('glow', scene);
      gl.intensity = 0.5;
      setTimeout(() => {
        setOpacity(0);
        setIsSceneReady(true);
      }, 4000);
    });
  };

  const toggleOpened = (mesh: CalendarMeshType) => {
    mesh.opened = !mesh.opened;
  };

  useEffect(() => {
    setLoadCalendar(true);
    if (!scene) return;
    // scene.debugLayer.show();
  }, [scene]);

  useEffect(() => {
    if (!posterMaterial.current || !bannerMaterial.current || !scene) return;
    if (type === 'xmas') {
      posterMaterial.current.albedoTexture = posterXmas_texture(scene);
      bannerMaterial.current.albedoTexture = bannerXmas_texture(scene);
    } else {
      posterMaterial.current.albedoTexture = posterPenguin_texture(scene);
      bannerMaterial.current.albedoTexture = bannerPenguin_texture(scene);
    }
  }, [type]);

  const setupCamera = (camera: ArcRotateCamera) => {
    camera.animations.push(alphaAnimation, radiusAnimation);
    sceneCamera.current = camera;
  };

  const setupCalendar = (calendar: CalendarMeshType) => {
    if (!scene) return;
    scene.stopAllAnimations();
    const root = calendar.getChildMeshes()[0];
    root.setEnabled(false);
    root.name = 'calendar';
    calendarRoot.current = root as CalendarMeshType;

    const actionManager = new ActionManager(scene);

    actionManager.registerAction(
      new ExecuteCodeAction(ActionManager.OnPickDownTrigger, function (e) {
        pickedDownMesh.current = e.meshUnderPointer as CalendarMeshType;
      }),
    );

    actionManager.registerAction(
      new ExecuteCodeAction(ActionManager.OnPickUpTrigger, function (e) {
        const pickedMesh = e.meshUnderPointer as CalendarMeshType;
        if (pickedMesh !== pickedDownMesh.current) return;
        const animationGroups = scene.animationGroups;
        const animation = scene.getAnimationGroupByName(pickedMesh.name);

        for (const animationGroup of animationGroups) {
          if (animationGroup.isPlaying) return;
        }

        if (!pickedMesh || !animation) return;

        animation.onAnimationEndObservable.addOnce(() => toggleOpened(pickedMesh));

        for (const mesh of scene.meshes) {
          const currentMesh = mesh as CalendarMeshType;
          if (currentMesh.opened) {
            const existingAnimation = scene.getAnimationGroupByName(currentMesh.name);
            if (!existingAnimation) return;
            if (animation.name !== existingAnimation.name) {
              existingAnimation.onAnimationEndObservable.addOnce(() => toggleOpened(currentMesh));
              existingAnimation.goToFrame(62);
              existingAnimation.speedRatio = -1;
              existingAnimation.play();
            }
          }
        }

        if (!pickedMesh.opened) {
          animation.goToFrame(0);
          animation.speedRatio = 1;
          animation.play();
        } else {
          animation.goToFrame(62);
          animation.speedRatio = -1;
          animation.play();
        }
      }),
    );

    for (const mesh of calendar.getChildMeshes()) {
      if (mesh.name === 'poster') {
        posterMaterial.current = mesh.material as PBRMaterial;
        posterMaterial.current.zOffset = -10;
        posterMaterial.current.zOffsetUnits = -10;
        posterMaterial.current.albedoTexture = posterPenguin_texture(scene);
      } else if (mesh.name === 'banner') {
        bannerMaterial.current = mesh.material as PBRMaterial;
        bannerMaterial.current.albedoColor = new Color3(1, 1, 1);
        bannerMaterial.current.albedoTexture = bannerPenguin_texture(scene);
      } else if (mesh.name.includes('drawer')) {
        const drawerMesh = mesh as CalendarMeshType;
        drawerMesh.actionManager = actionManager;
        drawerMesh.opened = false;
      } else if (mesh.name === 'base') {
        const baseMaterial = mesh.material as PBRMaterial;
        baseMaterial.albedoColor = new Color3(1, 1, 1);
        baseMaterial.emissiveColor = new Color3(0, 0.843, 1).toLinearSpace();
      } else if (mesh.name === 'water') {
        const waterMaterial = mesh.material as PBRMaterial;
        waterMaterial.transparencyMode = 2;
        waterMaterial.albedoColor = new Color3(1, 1, 1).toLinearSpace();
        waterMaterial.emissiveColor = new Color3(0.286, 0.494, 1).toLinearSpace();
        waterMaterial.metallic = 1;
        waterMaterial.roughness = 0.3;
      } else if (mesh.name === 'iceberg') {
        const icebergMaterial = mesh.material as PBRMaterial;
        icebergMaterial.albedoColor = new Color3(0, 0.894, 1).toLinearSpace();
        icebergMaterial.emissiveColor = new Color3(0.286, 0.494, 1).toLinearSpace();
        icebergMaterial.metallic = 1;
        icebergMaterial.roughness = 0.2;
        icebergMaterial.emissiveTexture = calendarBaseTexture(scene);
      }
    }
  };

  return (
    <Scene
      clearColor={new Color4(0.043, 0.043, 0.1, 1)}
      onSceneMount={(scene: SceneEventArgs) => {
        setupScene(scene);
      }}
    >
      <arcRotateCamera
        name="arcRotateCamera"
        target={new Vector3(0, -2, 0)}
        alpha={-Math.PI / 2}
        beta={Math.PI / 2 - 0.05}
        radius={1000}
        lowerBetaLimit={Math.PI / 2 - 0.05}
        upperBetaLimit={Math.PI / 2 - 0.05}
        lowerRadiusLimit={8}
        minZ={0.01}
        panningSensibility={0}
        onCreated={(camera: ArcRotateCamera) => {
          setupCamera(camera);
        }}
      />
      <hemisphericLight name="hemisphericLight" direction={new Vector3(0, 1, 0)} specular={new Color3(0, 0, 0)} />

      <Suspense fallback={<plane name="plane" size={0.2}></plane>}>
        {loadCalendar && (
          <Model
            name="calendar"
            sceneFilename="calendar.glb"
            rootUrl="./assets/mesh/"
            onCreated={(calendar: AbstractMesh) => {
              setupCalendar(calendar as CalendarMeshType);
            }}
          ></Model>
        )}
      </Suspense>
    </Scene>
  );
}

export default Calendar;
