import { CustomButtonWrapper } from './CustomButton.css';

type CustomButtonProps = {
  handleToggle: () => void;
  opacity: number;
};

export default function CustomButton({ handleToggle, opacity }: CustomButtonProps) {
  return (
    <CustomButtonWrapper onClick={handleToggle} $opacity={opacity}>
      THEMES
    </CustomButtonWrapper>
  );
}
