import { LoadingScreenWrapper } from './LoadingScreen.css';

type LoadingScreenProps = {
  opacity: number;
};
export default function LoadingScreen({ opacity }: LoadingScreenProps) {
  return (
    <LoadingScreenWrapper $opacity={opacity}>
      <img src={'./assets/textures/loading.gif'} alt=""></img>
      <h1>LOADING...</h1>
    </LoadingScreenWrapper>
  );
}
