import { useEffect, useState } from 'react';
import { Engine } from 'react-babylonjs';
import Calendar from '../scenes/Calendar';
import { CanvasWrapper } from './RenderingCanvas.css';
import CustomButton from '../components/CustomButton';
import LoadingScreen from '../components/LoadingScreen';
import Snowfall from 'react-snowfall';
import { snowflake1 } from '../scenes/images';

export default function RenderingCanvas() {
  const [type, setType] = useState<'xmas' | 'penguin'>('penguin');
  const [loader, setLoader] = useState<boolean>(true);
  const [opacity, setOpacity] = useState<number>(1);
  const [buttonOpacity, setButtonOpacity] = useState<number>(0);

  const handleToggle = () => {
    if (type === 'xmas') {
      setType('penguin');
    } else {
      setType('xmas');
    }
  };

  useEffect(() => {
    if (opacity === 0) {
      setTimeout(() => setLoader(false), 2000);
    }
  }, [opacity]);

  return (
    <CanvasWrapper>
      {loader && <LoadingScreen opacity={opacity} />}
      {!loader && <Snowfall color="white" snowflakeCount={350} radius={[1, 20]} images={[snowflake1]} />}
      <CustomButton opacity={buttonOpacity} handleToggle={handleToggle} />
      <Engine antialias adaptToDeviceRatio canvasId="babylonJS">
        <Calendar type={type} setOpacity={setOpacity} setButtonOpacity={setButtonOpacity} />
      </Engine>
    </CanvasWrapper>
  );
}
