import { Texture, VideoTexture } from '@babylonjs/core';
import type { Scene as SceneType } from '@babylonjs/core';

import { posterXmas, posterPenguin, bannerXmas, bannerPenguin, calendarBaseImage } from './images';

const posterXmas_texture = (scene: SceneType) => new Texture(posterXmas, scene, false, false);

const posterPenguin_texture = (scene: SceneType) => new Texture(posterPenguin, scene, false, false);

const bannerXmas_texture = (scene: SceneType) =>
  new VideoTexture('banner', bannerXmas, scene, false, true, undefined, {
    muted: true,
  });

const bannerPenguin_texture = (scene: SceneType) =>
  new VideoTexture('banner', bannerPenguin, scene, false, true, undefined, {
    muted: true,
  });

const calendarBaseTexture = (scene: SceneType) => new Texture(calendarBaseImage, scene, false, false);

export { posterXmas_texture, posterPenguin_texture, bannerXmas_texture, bannerPenguin_texture, calendarBaseTexture };
