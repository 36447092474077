import { Animation, SineEase, EasingFunction } from '@babylonjs/core';

const easingFunction = new SineEase();
easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);

const alphaAnimation = new Animation(
  'alphaAnimation',
  'alpha',
  60,
  Animation.ANIMATIONTYPE_FLOAT,
  Animation.ANIMATIONLOOPMODE_CONSTANT,
);

alphaAnimation.setEasingFunction(easingFunction);

const alphaKeys = [];

alphaKeys.push({
  frame: 0,
  value: -Math.PI / 2,
});
alphaKeys.push({
  frame: 100,
  value: (2 * Math.PI) / 3 - 0.1,
});

alphaAnimation.setKeys(alphaKeys);

const radiusAnimation = new Animation(
  'radiusAnimation',
  'radius',
  60,
  Animation.ANIMATIONTYPE_FLOAT,
  Animation.ANIMATIONLOOPMODE_CONSTANT,
);

radiusAnimation.setEasingFunction(easingFunction);

const radiusKeys = [];

radiusKeys.push({
  frame: 0,
  value: 1000,
});
radiusKeys.push({
  frame: 100,
  value: 30,
});

radiusAnimation.setKeys(radiusKeys);

export { alphaAnimation, radiusAnimation };
