import { createGlobalStyle } from 'styled-components/macro';
import reset from 'styled-reset';

export default createGlobalStyle`

${reset};

  html {
    overflow: hidden;      
  * {
    box-sizing: border-box
  } 
`;
